var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container",
    attrs: {
      "id": "dashboard"
    }
  }, [_vm.isNoRole ? _c('div', {
    staticClass: "card"
  }, [_c('p', [_vm._v("Derzeit dem User keine Rolle und keine Rechte zugewiesen!")]), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/useraccount"
    }
  }, [_vm._v("Mein Konto")])], 1) : _vm._e(), _vm._isAdmin || _vm._isSv ? _c('span', [_c('div', {
    staticClass: "wz-flexer _jcc _fww"
  }), _c('CrmOpenProjects', {
    attrs: {
      "counts": 20,
      "ltview": 'table'
    }
  }), _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-conten"
  }, [_c('OpenCalendar')], 1)]), _vm._m(1), _vm._m(2), _vm._m(3)], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Map/Kalender"), _c('div', {
    staticClass: "fr"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Sharepoint allgemein"), _c('div', {
    staticClass: "fr"
  })])]), _c('div', {
    staticClass: "card-content"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Einstellungen"), _c('div', {
    staticClass: "fr"
  })])]), _c('div', {
    staticClass: "card-content"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Nachrichten"), _c('div', {
    staticClass: "fr"
  })])]), _c('div', {
    staticClass: "card-content"
  })]);
}];
export { render, staticRenderFns };