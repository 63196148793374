import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters, mapState, mapActions } from "vuex";
var vu = null;
export default {
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  data: function data() {
    return {
      zoom: 11,
      center: {
        lat: 48.284987,
        lng: 16.367482
      },
      maincenter: {
        lat: 48.284987,
        lng: 16.367482
      },
      selected: {
        id: "",
        name: "keine Auswahl"
      },
      showList: false
    };
  },
  watch: {
    selected: function selected(nv) {
      vu.Set(["foruser", nv.id]);
    }
  },
  computed: _objectSpread({}, mapState(["foruser", "FB_general"])),
  methods: _objectSpread({}, mapActions(["_combinedApiCalls"]))
};